<template>
  <v-app>
    <v-main>
       <div class="ooo_main">
         <div class="child">
           <div class="text"><h1>404</h1></div>
           <div class="msg">
            <span>Employee not found.</span>
            <span>Employee on vacation.</span> 
            <span>Will revert back soon.</span>
           </div>
         </div>
       </div>
    </v-main>
  </v-app>
</template>

<script>
//import CompassNeedleViewer from './components/CompassNeedle/CompassNeedleViewer.vue';
//import MotionPicker from "./components/MotionPicker.vue";
export default {
  name: "App",
  components: {
    //CompassNeedleViewer,
  },
  created() {

  },
  mounted() {
   
  },
  data: () => ({

  }),
  methods: {

  },
};
</script>
<style scoped>
.sites_location {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.sites_location_btns {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}
.ooo_main{
  height:100dvh;
  width:100%;
  background: rgba(224, 240, 238, 0.415);
  border-radius: 20px;
  margin:10px;
}
.child {
  display:flex;
  flex-direction: row;
  gap:5px;
  height:100%;
  width:100%;
}
.text {
  flex:1;
  color: rebeccapurple;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text > h1 {
  font-size: 30vh;
  transition: all 1s linear;
}
.text > h1:hover {
  color: red;
  animation: shake 0.5s infinite;
}
.msg{
  flex:1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 5vh;
}
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
</style>
